// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.init-red-bg16 {
  background: red;
  font-size: 50px;
}

.abspage16 {
  padding-right: 50%;
}`, "",{"version":3,"sources":["webpack://./src/pages/HyperCloud/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,eAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":[".init-red-bg16 {\n    background: red;\n    font-size: 50px;\n}\n\n.abspage16{\n    padding-right: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
