// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-link {
  font-weight: normal;
  transition: all 0.3s ease;
}

.footer-link:hover {
  font-weight: bold; /* Change font weight on hover */
  color: #005eff; /* Change color on hover */
  text-decoration: underline; /* Underline on hover */
}

.footer-link:active {
  color: #ff0000; /* Change color when link is active (clicked) */
}`, "",{"version":3,"sources":["webpack://./src/common/footer/styles.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,yBAAA;AACJ;;AAEE;EACE,iBAAA,EAAA,gCAAA;EACA,cAAA,EAAA,0BAAA;EACA,0BAAA,EAAA,uBAAA;AACJ;;AAEE;EACE,cAAA,EAAA,+CAAA;AACJ","sourcesContent":[".footer-link {\n    font-weight: normal; \n    transition: all 0.3s ease; \n  }\n  \n  .footer-link:hover {\n    font-weight: bold; /* Change font weight on hover */\n    color: #005eff; /* Change color on hover */\n    text-decoration: underline; /* Underline on hover */\n  }\n  \n  .footer-link:active {\n    color: #ff0000; /* Change color when link is active (clicked) */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
