// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.init-red-bg12 {
  background: red;
  font-size: 50px;
  margin-right: 30%;
}

.abspage12 {
  padding-right: 80%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Blogs/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":[".init-red-bg12 {\n    background: red;\n    font-size: 50px;\n    margin-right: 30%;\n}\n\n.abspage12{\n    padding-right: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
